<template>
  <fm-modal title="采购申请" v-model="modal" width="1200" v-if="modal">
    <div class="p-detial-from">
      <div class="right">
        <fm-title titleText="采购明细"></fm-title>
        <fm-form label-align="left">
          <fm-form-item label="职能科室" v-if="orgList.length > 1">
            <fm-select absolute filterable :clearable="true" v-model="formData.busOrgId">
              <fm-option v-for="item in orgList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
            </fm-select>
          </fm-form-item>
          <fm-form-item label="类型">
            <fm-select @change="formData.method = null" absolute filterable :clearable="true" v-model="formData.type">
              <fm-option v-for="item in typeList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
            </fm-select>
          </fm-form-item>
          <fm-form-item label="采购方式">
            <fm-select absolute filterable :clearable="true" v-model="formData.method">
              <fm-option v-for="item in formData.type ? methodTypeList[formData.type] || [] : []" :key="item" :value="item" :label="item"></fm-option>
            </fm-select>
          </fm-form-item>
          <fm-form-item label="数量">
            <fm-input-new @change="onChange('num')" v-model="formData.num" placeholder="数量"></fm-input-new>
          </fm-form-item>
          <fm-form-item label="单价">
            <fm-input-new @change="onChange('price')" v-model="formData.price" placeholder="单价"></fm-input-new>
          </fm-form-item>
          <fm-form-item label="总价">
            <fm-input-new v-model="formData.total" disabled placeholder="总价"></fm-input-new>
          </fm-form-item>
        </fm-form>
        <div class="model-btn">
          <fm-btn style="margin-right: 30px;" @click="formSubmit">保存</fm-btn>
          <fm-btn @click="modal = false">取消</fm-btn>
        </div>
      </div>
      <div class="left-a">
        <div class="left">
        <fm-title titleText="采购明细"></fm-title>
        <div class="purchase-batch-detail-list-search">
          <fm-input-new style="width:100px;" size="small" placeholder="输入名称搜索" v-model="searchText"></fm-input-new>
        </div>
        <div class="purchase-batch-detail-list">
          <a href="javascript:;" @click="chooseDetail(item)" class="purchase-batch-detail-item" :class="{'purchase-batch-detail-item-a': choosePurchaseBatchDetailIds.includes(item.id)}"  v-for="item in showPurchaseBatchDetail" :key="item.id">
            {{item.code + ' ' + (item.orgName || '') + ' ' + item.goodsName}}
          </a>
        </div>
      </div>
      <div class="center">
        <fm-title titleText="已选采购明细"></fm-title>
        <purchase-batch-detail-info type="column" :purchaseBatchDetailList="choosePurchaseBatchDetailList"></purchase-batch-detail-info>
      </div>
      </div>
    </div>
  </fm-modal>
</template>

<script>
import {
  purchaseMethodBatchDetailsRequest
} from '../../../../api'

import purchaseBatchDetailInfo from './purchaseBatchDetailInfoAdd'

export default {
  components: {
    purchaseBatchDetailInfo
  },
  props: {
    data: { type: Object, defualt: () => null },
    orgList: { type: Array, defualt: () => [] },
    typeList: { type: Array, defualt: () => [] },
    purchaseBatchDetailList: { type: Array, defualt: () => [] },
  },
  watch: {
    data: {
      deep: true,
      handler () {
        this.formData = this.data ? JSON.parse(JSON.stringify(this.data)) : {
          total: ''
        }
        this.choosePurchaseBatchDetailIds = this.data && this.data.purchaseBatchDetailIds ? this.data.purchaseBatchDetailIds.split(',').map(v => Number(v)) : []
      },
      immediate: true
    }
  },
  data () {
    return {
      choosePurchaseBatchDetailIds: [],
      formData: {
        total: ''
      },
      searchText: '',
      step: 0,
      modal: false,
      loading: false
    }
  },
  computed: {
    choosePurchaseBatchDetailList () {
      let data = []
      if (this.choosePurchaseBatchDetailIds.length > 0) {
        data = this.purchaseBatchDetailList.filter(v => this.choosePurchaseBatchDetailIds.includes(v.id))
      }
      return data
    },
    methodTypeList () {
      let data = {
        mini: ['询价', '单一来源采购', '竞争性谈判'],
        small: ['询价', '单一来源采购', '竞争性谈判'],
        big: ['公开招标', '询价', '邀请招标', '单一来源采购']
      }
      return data
    },
    showPurchaseBatchDetail () {
      let allData = this.purchaseBatchDetailList
      if (this.searchText) {
        allData = allData.filter(item => (item.code + ' ' + (item.orgName || '') + ' ' + item.goodsName).includes(this.searchText))
      }
      return allData
    }
  },
  methods: {
    chooseDetail (item) {
      if (this.choosePurchaseBatchDetailIds.includes(item.id)) {
        this.choosePurchaseBatchDetailIds = this.choosePurchaseBatchDetailIds.filter(v => v !== item.id)
      } else {
        this.choosePurchaseBatchDetailIds.push(item.id)
      }
    },
    onChange (key) {
      if (isNaN(this.formData[key])) {
        this.$notify({
          title: '系统提示',
          message: '请输入数值',
          type: 'warning'
        })
        this.formData[key] = ''
        this.$set(this.formData, 'total', 0)
        return
      }
      if (this.formData.num && this.formData.price && !isNaN(this.formData.num) && !isNaN(this.formData.price)) {
        this.$set(this.formData, 'total', Number((this.formData.num * this.formData.price).toFixed(2)))
      } else {
        this.$set(this.formData, 'total', 0)
      }
    },
    async formSubmit () {
      if (this.loading) {
        return
      }
      if (!this.formData.busOrgId) {
        if (this.orgList.length === 1) {
          this.formData.busOrgId = this.orgList[0].key
        } else {
          this.$notify({
            title: '系统提示',
            message: '请选择职能科室',
            type: 'info'
          })
          return
        }
      }
      if (!this.formData.type) {
        this.$notify({
          title: '系统提示',
          message: '请选择类型',
          type: 'info'
        })
        return
      }
      if (!this.formData.method) {
        this.$notify({
          title: '系统提示',
          message: '请选择采购方式',
          type: 'info'
        })
        return
      }
      this.loading = true
      let data = this.formData
      data.purchaseBatchDetailIds = this.choosePurchaseBatchDetailIds.length > 0 ? this.choosePurchaseBatchDetailIds.join(',') : null
      try {
        if (data.id) {
          await purchaseMethodBatchDetailsRequest.update(data.id, data)
        } else {
          await purchaseMethodBatchDetailsRequest.add(data)
        }
        this.$emit('dataUpdate', 'add')
        this.modal = false
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="less">
.p-detial-from {
  display: flex;
  height: 70vh;
  .center {
    max-height: 100%;
    overflow-y: auto;
    width: 380px;
    padding: 0 20px;
  }
  .left {
    flex: 1;
    border-radius: 5px;
  }
  .left-a {
    border: 1px solid #e8eaec;
    border-radius: 5px;
    flex: 1;
    display: flex;
  }
  .right {
    margin-right: 20px;
    width: 500px;
  }
  .purchase-batch-detail-list-search {
    margin-left: 10px;
  }
  .purchase-batch-detail-list {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    max-height: calc(100% - 80px);
    overflow-y: auto;
  }
  .purchase-batch-detail-item {
    background: #e8eaec;
    color: #515a6e;
    line-height: 28px;
    padding: 0 10px;
    border-radius: 5px;
    margin: 10px 0 0 10px;
  }
  .purchase-batch-detail-item-a {
    background: #F4628F;
    color: #fff;
  }
}
.model-btn {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
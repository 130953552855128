<template>
  <div class="purchase-method-detail-base-info">
    <div class="base-info info-block">
      <div class="info-item">
        <div class="label">类型：</div>
        <div class="value">{{data.typeLabel}}</div>
      </div>
      <div class="info-item">
        <div class="label">采购方式：</div>
        <div class="value">{{data.method}}</div>
      </div>
      <div class="info-item">
      <div class="label">单价：</div>
      <div class="value">{{data.price == null ? '' : (data.price + '元')}}</div>
    </div>
    <div class="info-item">
      <div class="label">数量：</div>
      <div class="value">{{data.num == null ? '' : data.num}}</div>
    </div>
    <div class="info-item">
      <div class="label">总价：</div>
      <div class="value">{{data.total == null ? '' : (data.total + '元')}}</div>
    </div>
      <div class="info-item">
        <div class="label">职能科室：</div>
        <div class="value">{{data.busOrgName}}</div>
      </div>
      <div class="info-item">
        <div class="label">申请人：</div>
        <div class="value">{{data.userName}}</div>
      </div>
    </div>
    <div class="detail-info info-block" v-for="item in purchaseBatchDetailList" :key="item.id">
      <div style="width: 100%">
        关联采购明细：{{item.code}}
      </div>
    <div class="info-item">
      <div class="label">申报部门：</div>
      <div class="value">{{item.orgName}}</div>
    </div>
    <div class="info-item">
      <div class="label">申请人：</div>
      <div class="value">{{item.userName}}</div>
    </div>
    <div class="info-item">
      <div class="label">项目名称：</div>
      <div class="value">{{item.goodsName}}</div>
    </div>
    <div class="info-item">
      <div class="label">可选品牌及规格型号：</div>
      <div class="value">{{item.typeDetail}}</div>
    </div>
    <div class="info-item">
      <div class="label">单价：</div>
      <div class="value">{{item.price + '元'}}</div>
    </div>
    <div class="info-item">
      <div class="label">数量：</div>
      <div class="value">{{item.num}}</div>
    </div>
    <div class="info-item">
      <div class="label">总价：</div>
      <div class="value">{{item.total + '元'}}</div>
    </div>
    <div class="info-item">
      <div class="label">申请理由：</div>
      <div class="value">{{item.reason}}</div>
    </div>
    </div>
    <div class="detail-info info-block" v-if="purchaseBatchDetailList.length === 0">
      <div class="label">暂无关联采购明细</div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    data: { type: Object, defualt: () => null }
  },
  computed: {
    purchaseBatchDetailList () {
      return this.data && this.data.purchaseBatchDetailList ? this.data.purchaseBatchDetailList : []
    }
  }
}
</script>

<style lang="less" scoped>
.purchase-method-detail-base-info {
  color: #515a6e;
  .info-block {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
  }
  .detail-info {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #e8eaec;
  }
  .info-item {
    display: flex;
    margin-right: 50px;
    .label {
      margin-right: 0px;
    }
  }
}
</style>
<template>
  <div class="purchase-method-detail-file-info">
    <file-manage
      v-if="fileFuns.get"
      :file-data-ids="data.fileIds"
      @addFile="addFile"
      :funs="fileFuns"
      @delFile="delFile"
    />
  </div>
</template>

<script>
import {
  purchaseMethodBatchDetailsRequest
} from '../../../../api'
import FileManage from '@/components/base/FileManage'

export default {
  components: {
    FileManage
  },
  props: {
    data: { type: Object, defualt: () => null }
  },
  computed: {
    fileFuns () {
      let type = this.$router.history.current.query.type
      return {
        get: this.$authFunsProxyNoRoute['internalcontrol.purchase_method.' + type + '-viewDetailFile'],
        upload: this.$authFunsProxyNoRoute['internalcontrol.purchase_method.' + type + '-uploadDetail'],
        del: this.$authFunsProxyNoRoute['internalcontrol.purchase_method.' + type + '-delDetailFile']
      }
    }
  },
  methods: {
    async addFile (data) {
      if (!this.data.fileIds.includes(data.id)) {
        this.data.fileIds.push(data.id)
        purchaseMethodBatchDetailsRequest.files(this.data.id, {
          fileIds: this.data.fileIds.join(',')
        })
      }
    },
    async delFile (data) {
      const index = this.data.fileIds.findIndex(v => data.id === v)
      if (index > -1) {
        this.data.fileIds.splice(index, 1)
        purchaseMethodBatchDetailsRequest.files(this.data.id, {
          fileIds: this.data.fileIds.join(',')
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.purchase-method-detail-file-info {
  
}
</style>
<template>
  <div class="purchase-detail-page">
    <fm-title title-text="采购过程明细" :titleMenus="titleMenus" @clickTitleMenu="clickTitleMenu">
      <div class="title-sort">
        <div class="fm-tabs fm-tabs-norm">
          <div class="fm-tabs-nav">
            <div @click="dealStatus = item.key" class="fm-tabs-nav-item" :class="{'fm-tabs-nav-active': item.key === dealStatus}" v-for="item in dealSwitch" :key="item.key">
              {{item.label}}
            </div>
          </div>
        </div>
      </div>
    </fm-title>
    <deal-block
      class="p-d-s"
      v-if="dealStatus === 'wait_deal' && !batchDeal && waitDealDataList.length > 0"
      :dataList="waitDealDataList"
      :statusMap="statusMap"
      :orgList="orgList"
      :typeList="typeList"
      :batchData="batchData"
      @dataUpdate="dataUpdate"
      :purchaseBatchDetailList="purchaseBatchDetailList">
    </deal-block>
    <batch-deal-block
      class="p-d-s"
      v-else-if="dealStatus === 'wait_deal' && batchDeal && waitDealDataList.length > 0"
      :typeList="typeList"
      :dataList="waitDealDataList"
      :batchData="batchData"
      @dataUpdate="dataUpdate">
    </batch-deal-block>
    <list-info
      class="p-d-s"
      v-else-if="dealStatus !== 'wait_deal' && (dealStatus === 'dealed' ? dealedDataList : dataList).length > 0"
      :dataList="dealStatus === 'dealed' ? dealedDataList : dataList"
      :config="config"
      :statusMap="statusMap"
      @dataUpdate="dataUpdate">
    </list-info>
    <div class="p-d-s-n" v-else>
      <div>暂无数据</div>
    </div>
    <detail-form :typeList="typeList" @dataUpdate="dataUpdate" :orgList="orgList" :purchaseBatchDetailList="purchaseBatchDetailList" ref="dForm" :data="chooseData"></detail-form>
  </div>
</template>

<script>
import detailForm from './components/details/form'
import dealBlock from './components/details/dealBlock'
import batchDealBlock from './components/details/batchDealBlock'
import listInfo from './components/details/list'

import {
  purchaseMethodBatchRequest,
  purchaseBatchDetailsRequest,
  purchaseMethodBatchDetailsRequest,
  statusManageRequest
} from '../../api'

export default {
  components: {
    detailForm,
    dealBlock,
    batchDealBlock,
    listInfo
  },
  data () {
    return {
      config: {},
      dealSwitch: [{key: 'wait_deal', label: '待处理'}, {key: 'dealed', label: '已处理'}, {key: 'all', label: '全部'}],
      dealStatus: 'wait_deal',
      typeList: [{key: 'mini', label: '金额2000元以下'}, {key: 'small', label: '金额2000元以上'}, {key: 'big', label: '大型采购'}],
      batchDeal: false,
      chooseData: null,
      dataList: [],
      batchId: null,
      batchData: null,
      pageType: null,
      canAdd: false,
      statusMap: {},
      purchaseBatchDetailList: []
    }
  },
  created () {
    this.$store.dispatch('loadOrgList')
    this.$store.dispatch('loadUserList')
    this.loadConfig()
  },
  activated () {
    this.init()
  },
  computed: {
    waitDealDataList () {
      return this.dataList.filter(v => v.actions && v.actions.filter(v1 => v1.key !== 'back_status_switch').length > 0)
    },
    dealedDataList () {
      return this.dataList.filter(v => !v.actions || v.actions.filter(v1 => v1.key !== 'back_status_switch').length === 0)
    },
    titleMenus () {
      return this.canAdd ? [{key: 'batchDeal', label: '批量操作'}, {key: 'add', label: '添加采购过程申请'}] : [{key: 'batchDeal', label: '批量操作'}]
    },
    orgList () {
      let allData = this.$store.getters.orgList
      let batchBusOrgIds = this.batchData && this.batchData.busOrgIds ? this.batchData.busOrgIds.split(',').map(v => Number(v)) : []
      let myOrgIds = this.$store.getters.currentOrgId ? [this.$store.getters.currentOrgId] : []
      return allData.filter(v => batchBusOrgIds.includes(v.key) && myOrgIds.includes(v.key))
    }
  },
  methods: {
    async loadConfig () {
      let c = await statusManageRequest.config({
        statusGroupKey: 'purchaseMethodBatchDetail'
      })
      c.statusList.forEach(v => {
        this.statusMap[v.statusKey] = v.label
      })
      this.config = {
        big: c.statusSwitchList.filter(v => v.isHost && v.tag === 'big'),
        small: c.statusSwitchList.filter(v => v.isHost && v.tag === 'small'),
        mini: c.statusSwitchList.filter(v => v.isHost && v.tag === 'mini'),
      }
    },
    async dataUpdate () {
      await this.loadData()
    },
    async init () {
      this.dealStatus = 'wait_deal'
      this.batchDeal = false
      this.chooseData = null
      this.dataList = []
      this.batchId = this.$router.history.current.query.id
      this.pageType = this.$router.history.current.query.type
      await this.loadBatch()
      this.loadData()
      this.loadPurchaseBatchDetailList()
    },
    async loadBatch () {
      if (!this.batchId || !this.pageType) {
        return
      }
      let datas = await purchaseMethodBatchRequest.get({
        id: this.batchId
      })
      if (datas.length !== 1) {
        this.$notify({
          title: '系统提示',
          message: '采购过程批次不存在',
          type: 'warning'
        })
        return
      }
      this.batchData = datas[0]
      this.canAdd = (this.batchData.actions || []).find(v => v.key === 'add_detail') && true
    },
    async loadData () {
      if (!this.batchData || !this.batchId || !this.pageType) {
        return
      }
      let datas = []
      if (this.pageType !== 'index') {
        datas = await purchaseMethodBatchDetailsRequest.getByAuth({
          purchaseMethodBatchId: this.batchId,
          statusActionRecord: 1
        })
      } else {
        datas = await purchaseMethodBatchDetailsRequest.get({
          purchaseMethodBatchId: this.batchId,
          statusActionRecord: 1
        })
      }
      datas.forEach(v => {
        v.fileIds = (v.fileIds || '').split(',').filter(v => v).map(v => Number(v))
        v.typeLabel = (this.typeList.find(v1 => v1.key === v.type) || {label: ''}).label
      })
      this.dataList = datas
    },
    async loadPurchaseBatchDetailList () {
      if (!this.batchData || !this.batchData.year) {
        return
      }
      let datas = await purchaseBatchDetailsRequest.get({
        status: 'end',
        year: this.batchData.year
      })
      this.purchaseBatchDetailList = datas
    },
    clickTitleMenu (key) {
      if (key === 'add') {
        if (this.orgList.length === 0) {
          this.$notify({
            title: '系统提示',
            message: '无可填报职能科室',
            type: 'warning'
          })
          return
        }
        this.chooseData = {
          purchaseMethodBatchId: this.batchId
        }
        this.$refs.dForm.modal = true
      } else if (key === 'batchDeal') {
        if (this.dealStatus !== 'wait_deal') {
          this.dealStatus = 'wait_deal'
          this.batchDeal = true
        } else {
          this.batchDeal = !this.batchDeal
        }
      }
    }
  }
}
</script>

<style scoped lang="less">
.purchase-detail-page {
  background: #fff;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  margin: 10px;
  .title-sort {
    height: 100%;
    display: flex;
    align-items: center;
  }
  .p-d-c {
    width: calc(100% - 40px);
    height: calc(100% - 88px);
  }
  .p-d-s {
    width: 100%;
    height: calc(100% - 48px);
  }
  .p-d-s-n {
    width: 100%;
    height: calc(100% - 48px);
    font-size: 20px;
    color: #515a6e;
    display: flex;
    align-items: center;
    justify-content: center;
    div {
      padding: 20px;
    }
  }
  .left {
    height: calc(100% - 20px);
    overflow-y: auto;
    width: 300px;
    border-right: 1px solid #e8eaec;;
    padding-right: 20px;
  }
  .right {
    height: calc(100% - 20px);
    flex: 1;
    overflow-y: auto;
    border-top: 1px solid #e8eaec;;
    border-right: 1px solid #e8eaec;;
    border-bottom: 1px solid #e8eaec;;
    .right-btns {
      padding:10px 20px;
      display: flex;
      justify-content: space-around;
    }
  }
  .sign-item {
    margin-bottom: 10px;
  }
}
</style>